<template>
  <div>
    <div class="p-mb-4 p-ml-3">
      <h3 class="p-m-0">
        <i class="pi pi-chart-bar p-mr-2"></i>
        <span>Revenue by Country</span>
      </h3>
    </div>
    <Card class="overview-card revenue-card">
      <template slot="content">
        <iframe
          :src="iframeUrl"
          frameborder="0"
          width="100%"
          height="98%"
          class="chart"
          allowtransparency
        />
        <div class="revenue-card-link">
          Click to see
          <a href="https://stats-by-office.tools.collabasia.co" target="_blank">
            <b>Full Revenue Data here</b>
          </a>
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api";

@Component
export default class RevenueCard extends Vue {
  public iframeUrl = null;

  async beforeMount() {
    this.iframeUrl = (await api.getSboIframeUrl()).data;
  }
}
</script>

<style scoped>
.revenue-card-link {
  text-align: center;
  margin-top: -32px;
}

.revenue-card::v-deep .p-card-body,
.revenue-card::v-deep .p-card-body .p-card-content {
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  height: 100%;
}
</style>
