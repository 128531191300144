<template>
  <div>
    <div class="p-mb-4 p-ml-3" id="announcements">
      <h3 class="p-m-0">
        <i class="pi pi-info-circle p-mr-2"></i>
        <span>Announcements</span>
      </h3>
    </div>
    <Card class="overview-card announcement-card p-d-flex p-flex-column">
      <template slot="content">
        <div class="p-d-flex p-flex-column announcement-container">
          <ul class="announcement-contents">
            <li
              v-for="item in announcements"
              :key="item.id"
              class="announcement-item"
            >
              <div class="p-d-flex p-jc-between p-ai-center">
                <b v-if="item.title">{{ item.title }}: </b>
                <vue-markdown class="p-mb-0 announcement-content">
                  {{ item.body }}
                </vue-markdown>
                <Button
                  v-if="isAlpha"
                  icon="pi pi-times"
                  class="announcement-item-delete p-button-text p-button-danger p-button-rounded p-ml-2"
                  @click="deleteAnnouncement(item.id)"
                />
              </div>
            </li>
          </ul>
          <div
            class="announcement-input p-d-flex p-jc-between p-ai-center"
            v-if="isAlpha"
          >
            <Textarea
              :autoResize="true"
              rows="1"
              v-model="newAnnouncement"
              placeholder="Type to announce…"
            />
            <Button
              icon="pi pi-angle-right"
              style="
                border-radius: 8px;
                border-color: transparent;
                color: white;
                background-color: var(--butterscotch);
              "
              @click="publishAnnouncement"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Announcement } from "@/interfaces";
import { api } from "@/api";
import VueMarkdown from "vue-markdown";
import { readIsAlpha } from "@/store/auth/getters";

@Component({
  components: {
    VueMarkdown,
  },
})
export default class AnnouncementCard extends Vue {
  public announcements: Announcement[] = [];
  public newAnnouncement: string = null;
  public limit = 20;

  @Watch("limit", { immediate: true })
  public async updateAnnouncements() {
    const response = await api.listRecentAnnouncements(this.limit);
    if (response) {
      this.announcements = response.data;
    }
  }

  public async publishAnnouncement() {
    const announcement = {
      title: null,
      body: this.newAnnouncement,
    };
    const response = await api.createAnnouncement(announcement);
    if (response) {
      this.newAnnouncement = null;
      await this.updateAnnouncements();
    }
  }

  public async mounted() {
    await this.updateAnnouncements();
  }

  get isAlpha() {
    return readIsAlpha(this.$store);
  }

  public async deleteAnnouncement(id) {
    const response = await api.deleteAnnouncement(id);
    if (response) {
      this["$toast"].add({
        severity: "success",
        summary: "Announcement deleted!",
        life: 3000,
      });
      await this.updateAnnouncements();
    } else {
      this["$toast"].add({
        severity: "error",
        summary: "Something went wrong...",
        detail: "Could not delete announcement",
        life: 3000,
      });
    }
  }
}
</script>

<style scoped>
.announcement-card {
  color: #ffffff;
  background-color: #4f5d75;
  padding: 0.6rem 1rem 1rem;
}

.announcement-contents {
  height: 0;
  margin-top: -0.5rem;
  flex-grow: 1;
  padding: 0 25px;
  overflow-y: auto;
}

.announcement-content {
  margin-top: 0;
  word-break: break-word;
}

.announcement-item-delete {
  visibility: hidden;
  flex-shrink: 0;
}

.announcement-item:hover .announcement-item-delete {
  visibility: visible;
}

.announcement-container {
  height: 100%;
}

.announcement-input {
  margin-top: 1rem;
  padding: 5px 10px;
  background: white;
  border-radius: 10px;
}

.announcement-input textarea {
  flex: 1 1 auto;
  border: none;
}

.announcement-input textarea:focus {
  box-shadow: none;
}
</style>
