


























































































































.milesontes-card::v-deep {
  .p-card-body,
  .p-card-body .p-card-content {
    width: 100%;
    height: 100%;
    padding: 1rem;
  }

  .p-progressbar {
    margin-top: 0.2rem;
    height: 0.3rem;
  }

  .progress-bar-90 {
    .p-progressbar .p-progressbar-value {
      background-color: #1eca7b;
    }
  }
  .progress-bar-80 {
    .p-progressbar .p-progressbar-value {
      background-color: #ffb822;
    }
  }

  .slick-prev {
    top: 60%;
    right: 50px;
    left: unset;
    border-radius: 100%;
    z-index: 2;

    &::before {
      color: #ccc;
    }
  }

  .slick-next {
    top: 60%;
    right: 25px;
    left: unset;
    border-radius: 100%;
    z-index: 2;

    &::before {
      color: #ccc;
    }
  }
}

.flex-grow {
  flex-grow: 1;
}

.loading-container {
  height: 100%;
}

a {
  text-decoration: none;
  color: #495057;

  &:visited,
  &:hover,
  &:active {
    color: #495057;
  }

  .p-button-main {
    width: 100%;
    margin-top: 0.5rem;
    font-size: 1rem;
  }
}

.award-container {
  margin-top: 0.2rem;

  &:first-child {
    margin-top: 0;
  }

  .item-container {
    padding-left: 1rem;
    cursor: pointer;

    .channel-image {
      border-radius: 50%;
      width: 60px;
      margin-right: 1rem;
    }
  }

  .award-title {
    font-size: 1rem;
  }

  .current-count {
    font-size: 28px;
  }

  .channel-title {
    font-size: 1.125rem;
    word-break: break-all;
  }

  .channel-info {
    word-break: break-all;
  }

  a {
    text-decoration: none;

    &:visited,
    &:hover,
    &:active {
      color: #495057;
    }
  }

  .font-bold {
    font-weight: bold;
  }

  .text-muted {
    color: #838383;
  }

  .text-small {
    font-size: 12px;
  }
}
