<template>
  <div class="Overview">
    <top-bar></top-bar>
    <div class="main-container">
      <div class="p-grid">
        <div class="p-col-6">
          <ContractsLatestExpiringCard></ContractsLatestExpiringCard>
        </div>
        <div class="p-col-6">
          <StatsCard></StatsCard>
        </div>
      </div>
      <div class="p-grid p-mt-6">
        <div class="p-col-6">
          <TopChannelsCard class="p-mb-6"></TopChannelsCard>
        </div>
        <div class="p-col-6">
          <RecentAccountsCard class="p-mb-6"></RecentAccountsCard>
        </div>
      </div>
      <div class="p-grid">
        <!-- <div class="p-col-6" v-if="isAlpha">
          <RevenueCard class="p-mb-6"></RevenueCard>
        </div> -->
        <div class="p-col-6">
          <AnnouncementCard class="p-mb-6"></AnnouncementCard>
        </div>
        <div class="p-col-6">
          <MilestonesCard class="p-mb-6"></MilestonesCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import AnnouncementCard from "@/views/overview/cards/AnnouncementCard.vue";
import ContractsLatestExpiringCard from "@/views/overview/cards/ContractsLatestExpiringCard.vue";
import MilestonesCard from "@/views/overview/cards/MilestonesCard.vue";
import RecentAccountsCard from "@/views/overview/cards/RecentAccountsCard.vue";
import RevenueCard from "@/views/overview/cards/RevenueCard.vue";
import StatsCard from "@/views/overview/cards/StatsCard.vue";
import TopChannelsCard from "@/views/overview/cards/TopChannelsCard.vue";
import OmniSearchBox from "@/components/OmniSearchBox.vue";
import UserInfo from "@/components/UserInfo.vue";
import { readIsAlpha } from "@/store/auth/getters";
import TopBar from "@/components/TopBar.vue";

@Component({
  components: {
    AnnouncementCard,
    ContractsLatestExpiringCard,
    MilestonesCard,
    RecentAccountsCard,
    RevenueCard,
    StatsCard,
    TopChannelsCard,
    OmniSearchBox,
    UserInfo,
    TopBar,
  },
})
export default class Overview extends Vue {
  get isAlpha() {
    return readIsAlpha(this.$store);
  }

  public mounted() {
    window["analytics"]["page"]("/overview");
  }
}
</script>

<style>
.Overview .main-container {
  padding: 2rem;
}

.overview-card {
  height: 550px;
  overflow: auto;
}

.Overview .p-card-title {
  font-size: 16px;
}

.Overview .p-card-content {
  font-size: 14px;
}
</style>
