.account-list-card {
  &.overview-card {
    height: 29.4rem;
  }

  &.p-card .p-card-content {
    padding: 0;
  }

  .p-card-body {
    padding: 0;
  }

  .p-datatable {
    min-height: 25.5rem;

    .p-datatable-loading-icon {
      position: absolute;
      top: 12rem;
      z-index: 1;
    }

    .p-datatable-tbody {
      font-family: Montserrat;
      font-size: 12px;
    }

    .p-datatable-thead {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 1;
    }

    .p-datatable-thead > tr > th {
      padding: 0.75rem;
    }

    .p-datatable-tbody > tr > td {
      padding: 1rem 0.75rem;
    }

    .p-column-header-content {
      font-size: 13px;
    }
  }

  .table {
    max-height: 25.5rem;
    overflow: auto;
  }

  .card-header {
    background-color: var(--dark-grey);
    color: var(--white);
    font-family: Montserrat;
    font-weight: bold;
    font-size: 14px;
  }

  .channel-image {
    display: inline-block;
    border-radius: 50%;
  }

  .name-column {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 9rem;
    font-weight: bold;
  }

  .views-column {
    min-width: 5.2rem;
  }

  .subs-column {
    min-width: 5.4rem;
  }

  .manager-badge {
    width: 5rem;
    text-align: center;
    background-color: #a9d3ff;
    color: #4f5d75;
    border-radius: 0;
    font-size: 10px;
    line-height: 1.5;
  }

  .dropdown {
    .p-dropdown-label.p-inputtext.p-inputtext {
      font-size: 0.875rem;
      padding: 0.33rem 0.5rem;
    }
  }
}
