



















































































.stat-item {
  height: 8rem;
  overflow: auto;
  font-family: Montserrat;

  .title {
    font-size: 14px;
    font-weight: bold;
  }

  .description {
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }

  .positive {
    color: green;
    font-weight: bold;
  }

  .negative {
    color: red;
    font-weight: bold;
  }

  .neutral {
    color: gray;
    font-weight: bold;
  }

  .count-container {
    padding: 1rem 0;
    border-bottom: 1px solid var(--dark-grey);

    .count {
      font-size: 36px;
      font-weight: bold;
    }
  }

  .info-icon {
    color: #2196f3;
  }
}
