








































































.list-card-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .header-title {
    margin: 0;
    color: #2d3142;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 14px;
  }
}

.list-card-body {
  background-color: #e0e0e0;
  height: 18rem;
  overflow-y: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: Montserrat;
  font-size: 12px;

  a {
    text-decoration: none;
    color: #495057;

    &:visited,
    &:hover,
    &:active {
      color: #495057;
    }
  }

  .list-item {
    border-top: 1px solid #ebeef5;
    background-color: white;
    cursor: pointer;
    margin: 0;
    max-width: 100%;

    .item-header {
      width: 0;
      flex-grow: 1;

      .item-avatar {
        overflow: hidden;
        flex-shrink: 0;
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
      }

      .item-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
        font-size: 0.8rem;
        color: #303133;
      }
    }

    .item-type-badge {
      background-color: #ecf5ff;
      color: #4f5d75;
      padding: 0.428rem 0.89rem 0.477rem 0.901rem;
      font-size: 0.6rem;
      max-width: 7.5rem;
      text-align: center;
    }

    .item-expiry-date {
      color: #ef373e;
    }
  }
}
