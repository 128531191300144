









































































































































































.stats-card {
  height: 21.2rem;
  background: transparent;
  box-shadow: none;
  padding: 0 1rem;

  .dropdown::v-deep {
    position: absolute;
    right: 57px;
    width: 9rem;

    .p-dropdown-label.p-inputtext.p-inputtext {
      font-size: 0.875rem;
      padding: 0.45rem 0.5rem;
    }
  }

  &::v-deep {
    .p-card-body {
      padding: 0;
    }

    .p-card-content {
      padding: 0;
    }

    .trend-icon {
      font-size: 0.75rem;
    }
  }

  .tabs {
    &::v-deep {
      .nav-tabs {
        margin-block-start: 0.5rem;
      }

      .nav-link {
        height: 34px;
        padding: 0.5rem 1rem 0;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-weight: normal;
        color: #222222;
        border-radius: 4px;

        &.active {
          background-color: #2d3142;
          color: var(--white);
        }
      }

      .nav-item a {
        margin-right: 0.5rem;
        font-weight: bold;
        background: rgba(169, 211, 255, 0.5);
      }
    }
  }

  .loading-container {
    height: 18rem;
    background: var(--bluey-grey);
    opacity: 0.3;
    border-radius: 0.5rem;
  }
}
