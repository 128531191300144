














































































































































































.channel-image {
  display: inline-block;
  border-radius: 50%;
  margin-left: 0.5rem;
}

.dropdown::v-deep {
  &.rank-field-dropdown {
    width: 6.8rem;
  }

  &.office-dropdown {
    width: 9rem;
  }
}
