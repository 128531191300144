






















































































































































































.list-avatar {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  margin-left: 0.5rem;
}

.dropdown::v-deep {
  &.account-type-dropdown {
    width: 10rem;
  }

  &.office-dropdown {
    width: 9rem;
  }
}
